<template>
  <v-container>
    <!-- Filters Section with Filter Button -->
    <v-card class="mb-4">
      <v-card-title>Filter Users</v-card-title>

      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6" md="6">
            <v-text-field label="Email" v-model="filters.email"></v-text-field>
          </v-col>

          <!-- Row 2: Country, Age Range, Gender -->
          <v-col cols="12" sm="6" md="6">
            <v-select
              :items="countries"
              label="Country"
              v-model="filters.country"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-select
              :items="genders"
              label="Gender"
              v-model="filters.gender"
              item-text="label"
              item-value="value"
            ></v-select>
          </v-col>

          <v-col cols="12" sm="6" md="6">
            <v-select
              :items="states"
              label="State"
              v-model="filters.state"
            ></v-select>
          </v-col>

          <v-col cols="12" sm="12" md="12">
            <v-range-slider
              v-model="filters.ageRange"
              :min="minAge"
              :max="maxAge"
              :step="1"
              label="Age Range"
              persistent-hint
              thumb-label="always"
              ticks
            ></v-range-slider>
            <div class="d-flex justify-space-between mt-2">
              <small>Min Age: {{ filters.ageRange[0] }}</small>
              <small>Max Age: {{ filters.ageRange[1] }}</small>
            </div>
          </v-col>

        </v-row>

        <!-- Filter Button -->
        <v-btn color="primary" @click="applyFilters">Apply Filters</v-btn>
      </v-card-text>
    </v-card>

    <!-- Users Table -->
    <v-data-table
      :headers="headers"
      :items="users"
      :items-per-page="limit"
      :server-items-length="totalUsers"
      :loading="loading"
      class="elevation-1"
    >
      <template v-slot:footer.page-text="{ pageStart, pageStop }">
        Showing {{ pageStart }} to {{ pageStop }} of {{ totalUsers }}
      </template>
    </v-data-table>

    <!-- Pagination -->
    <v-pagination
      v-model="page"
      :length="totalPages"
      :total-visible="10"
      @input="fetchUsers"
      class="my-4"
      color="primary"
    ></v-pagination>
  </v-container>
</template>

<script>
import RestResource from "../../services/dataServiceAdmin";

const service = new RestResource();

export default {
  data() {
    return {
      users: [],
      totalUsers: 0,
      totalPages: 1,
      page: 1,
      limit: 20,
      loading: false,
      filters: {
        firstName: "",
        lastName: "",
        country: "",
        email: "",
        ageRange: [0, 100],
        gender: "",
      },
      minAge: 0,
      maxAge: 100,
      headers: [
        { text: "First Name", value: "firstName" },
        { text: "Last Name", value: "lastName" },
        { text: "Country", value: "country" },
        { text: "Email", value: "email" },
        { text: "Age", value: "age" },
        { text: "Gender", value: "gender" },
        { text: "State", value: "state" },
      ],
      countries: ["Malaysia"],
      genders: [
        {
          label: "Male",
          value: "male",
        },
        {
          label: "Female",
          value: "female",
        },
      ],
      states: [],
    };
  },
  methods: {
    async fetchUsers() {
      this.$setLoader();
      try {
        const response = await service.fetchOnlinePanelUsers({
          page: this.page,
          limit: this.limit,
          ...this.filters,
        });

        this.users = response.data.docs;
        this.totalUsers = response.data.totalUsers;
        this.totalPages = response.data.totalPages;
      } catch (error) {
        console.error(error);
      } finally {
        this.$disableLoader();
      }
    },
    applyFilters() {
      this.page = 1;
      this.fetchUsers();
    },

    async getUniqueStates() {
      this.$setLoader();
      try {
        const response = await service.fetchUniqueStates();
        this.states = response.data
      } catch (error) {
        alert(error)
      } finally {
        this.$disableLoader();
      }
    }
  },
  mounted() {
    this.fetchUsers();
    this.getUniqueStates();
  },
};
</script>

<style scoped>
.v-card {
  padding: 16px;
  border-radius: 8px;
}

.v-data-table {
  margin-top: 16px;
}

.v-pagination {
  justify-content: center;
}
</style>
